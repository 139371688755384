import React from 'react';
import styles from './lines.module.scss';
import { Parallax } from 'react-parallax';

function LineOne() {
	return (
		<Parallax style={{ position: 'initial'}}
		renderLayer={percentage => (
			<span className={styles.one} style={{
				left: 200 + percentage * 50,
			}}/>
		)}
	>
	</Parallax>
	);
}

function LineTwo() {
	return (
		<Parallax style={{ position: 'initial'}}
		renderLayer={percentage => (
			<span className={styles.two} style={{
				left: 300 + percentage * 130,
			}}/>
		)}
	>
	</Parallax>
	);
}

export default ({linesStyle}) => {
	return (
		<div className={`${styles.lines} ${linesStyle}`}><LineOne /><LineTwo /></div>
	)			
}
