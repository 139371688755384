import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import Lines from '../components/elements/lines/lines';
import background from '../assets/images/background.jpg';

class ErrorPage extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const [footer] = get(this, 'props.data.allContentfulFooterMenu.edges')
		const [menu] = get(
			this,
			'props.data.allContentfulMainMenu.edges'
		)

		return (
			<div className='notfound-page'>
				<Layout
					title={'Pagina niet gevonden' + ' - ' + siteTitle}
					menuItems={menu.node.menuItems}
					brandLogo={menu.node.brandLogo.file.url}
					location={this.props.location}
					footerItems={footer.node.footerItems}
					linkedIn={footer.node.linkedIn}
					facebook={footer.node.facebook}
					youtube={footer.node.youtube}
					instagram={footer.node.instagram}
				>
					<div className="not-found-wrapper">
						<div className="wrapper">
							<h1>We hebben overal gezocht, maar kunnen de pagina niet vinden…</h1>
							<div className="content">
								<p>Dat is balen! Ben je op zoek naar iets leuks en dan krijg je een foutmelding. Misschien kunnen we je alsnog helpen met onderstaande pagina’s. Je mag ons ook altijd bellen, geen probleem!</p>
								<ul>
									<li><a href="javascript:history.back(1)">Terug naar vorige pagina</a></li>
									<li><a href="/">Naar de home pagina</a></li>
								</ul>
							</div>
						</div>
						<div className="background-wrapper">
							<Lines />
							<img src={background} />
						</div>
					</div>
				</Layout>
			</div>
		)
	}
}

export default ErrorPage
export const pageQuery = graphql`
	query ErrorPageQuery {
		site {
			siteMetadata {
				title
			}
		}
		allContentfulFooterMenu(filter: {title: {eq: "Footer"}}) {
			edges {
				node {
  					...Footer
        		}
      		}
		}
		allContentfulMainMenu(filter: {title: {eq: "Hoofdmenu"}}) {
			edges {
				node {
  					...MainMenu
        		}
      		}
		}
	}
`
